<template>
  <b-card
    no-body
    class="system-settings-card prozess-custom-entity-data"
  >
    <div>
      <sidebar-form
        v-if="customEntity.type !== CustomEntityType.VIEW"
        :visible="showForm"
        :resource-id="resourceId"
        :relationships="customEntity.relationships"
        :service="customService"
        @saved="refreshData()"
        @close="onCloseForm"
      />
      <div class="m-2 d-flex justify-content-between table-actions">
        <div class="d-flex">
          <div
            v-if="customEntity.type !== CustomEntityType.VIEW"
            class="mr-1"
          >
            <b-button
              id="create-button"
              variant="primary"
              :class="{ 'icon-disabled': $can('Create', permissionName) === false }"
              @click="$can('Create', permissionName) && create()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Create New') }}</span>
            </b-button>
            <b-tooltip target="create-button">
              {{ $can('Create', permissionName) === true ? $t('Create') : $t('You dont have permission to create') }}
            </b-tooltip>
          </div>
          <div>
            <imex-actions-dropdown
              :schema-name="Schema.CUSTOM"
              :entity-type="$route.params.key"
              :custom-entity-type="customEntity.type"
            />
          </div>
        </div>
        <div class="d-flex custom-entity-search">
          <prozess-search-input
            :value="search"
            :placeholder="$t('Search')"
            @input="handleSearch"
          />
        </div>
      </div>
      <div
        v-if="!customizeLoading"
        class="m-2 custom-entity-table"
      >
        <b-table
          ref="refTable"
          :busy="loading"
          :items="customEntityItems"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label, field }">
            <div
              :style="{
                'min-width': field.type === CustomFieldTypes.LongText ? '350px' : '',
              }"
            >
              <span style="white-space: nowrap">{{ label }}</span>
            </div>
          </template>

          <template #cell()="{ item, field }">
            <router-link
              v-if="field.partOfName || field.isTitle"
              class="text-nowrap"
              :to="{
                name: 'custom-entity-data-view',
                params: {
                  key: $route.params.key,
                  id: item.uuid_key,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
            >
              <item
                :value="item[field.key]"
                :type="field.type"
              />
            </router-link>
            <div
              v-else-if="field.key === INDICATOR_ACTIONS"
              class="text-nowrap"
            >
              <template v-if="customEntity.type === CustomEntityType.VIEW">
                <feather-icon
                  :id="`custom-entity__${item.uuid_key}-goto-main-entity-icon`"
                  icon="CornerLeftUpIcon"
                  size="16"
                  class="mx-1 cursor-pointer"
                  :class="{
                    'icon-disabled': !canGoToMainEntity,
                  }"
                  @click="canGoToMainEntity ? goToMainEntity(item) : null"
                />
                <b-tooltip
                  :title="canGoToMainEntity ? $t('Go to the main data source') : `${$t('no-view-permission-text')} ${mainSourceTable}`"
                  :target="`custom-entity__${item.uuid_key}-goto-main-entity-icon`"
                  placement="left"
                />
              </template>
              <template v-else>
                <feather-icon
                  :id="`edit-row-${item.uuid_key}-preview-icon`"
                  icon="EditIcon"
                  size="16"
                  class="mx-1 cursor-pointer"
                  :class="{
                    'icon-disabled': $can('Update', permissionName) === false,
                  }"
                  @click="$can('Update', permissionName) ? edit(item.uuid_key) : null"
                />
                <b-tooltip
                  :title="$can('Update', permissionName) === true ? $t('Edit') : $t('You dont have permission to update')"
                  :target="`edit-row-${item.uuid_key}-preview-icon`"
                  placement="left"
                />
                <feather-icon
                  :id="`delete-row-${item.uuid_key}-preview-icon`"
                  icon="TrashIcon"
                  size="16"
                  class="mx-1 cursor-pointer"
                  :class="{
                    'icon-disabled': $can('Delete', permissionName) === false,
                  }"
                  @click="remove(item)"
                />
                <b-tooltip
                  :title="$can('Delete', permissionName) === true ? $t('Delete') : $t('You dont have permission to remove')"
                  :target="`delete-row-${item.uuid_key}-preview-icon`"
                  placement="left"
                />
              </template>
            </div>
            <span
              v-else
              style="white-space: nowrap"
            >
              <item
                :value="item[field.key]"
                :type="field.type"
              />
            </span>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import { GenericTabs, INDICATOR_ACTIONS, Schema } from '@/constants/app'
import CustomFieldTypes, { customFieldTypeIcons } from '@/constants/customFieldTypes'
import { CustomEntityType } from '@/constants/customEntity'
import { mapRowData } from '@/helpers/customEntity'
import listMixinFactory from '@/mixins/list'
import RootEvents from '@/constants/rootEvents'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import SidebarForm from '@/views/pages/CustomEntity/Form/index.vue'
import Item from '@/views/pages/CustomEntity/List/Item.vue'
import goToMainEntityMixin from '@/mixins/goToMainEntity'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'

export default {
  components: {
    SidebarForm,
    Item,
    ImexActionsDropdown,
  },
  mixins: [
    listMixinFactory({
      isCustomTableColumns: true,
      isCustomService: true,
      isCustomizable: true,
      listFormat: true,
      routeName: 'custom-entity',
      sort: {},
    }),
    goToMainEntityMixin,
  ],
  props: {
    customEntity: {
      type: Object,
      required: true,
    },
    customService: {
      type: Object,
      default: null,
    },
    defaultSearchField: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      INDICATOR_ACTIONS,
      CustomEntityType,
      GenericTabs,
      Schema,
      CustomFieldTypes,
      customFieldTypeIcons,
      resourceId: null,
      showForm: false,
      showView: false,
      showHistory: false,
      searchField: null,
    }
  },
  computed: {
    permissionName() {
      return this.$route.params.key
    },

    searchableColumns() {
      return this.customEntity.columns.filter(item => item.searchable)
    },

    customEntityItems() {
      return mapRowData(this.items)
    },
  },
  async mounted() {
    this.$root.$on(RootEvents.GET_CUSTOM_ENTITIES, () => this.fetchList())
    await this.getEntityOrder()
  },
  destroyed() {
    this.$root.$off(RootEvents.GET_CUSTOM_ENTITIES)
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      removeCustomEntity: 'user/removeCustomEntity',
    }),
    async getEntityOrder() {
        const { response } = await this.$async(advancedFieldMgmtService.getorder(
          { schema: this.customEntity.schema,
            table: this.customEntity.key,
          }))
        const keyOrder = response.data
        if (keyOrder.length > 0) {
          let sorteDisplayColumn = JSON.parse(JSON.stringify(this.customEntity.columns))

          const lastCol = sorteDisplayColumn.pop()
          if (typeof lastCol === 'undefined') {
            sorteDisplayColumn.push(lastCol)
          }
          const unsortedJson = {}
          sorteDisplayColumn.forEach(field => { unsortedJson[field.key] = field })
          const sortedList = []
          keyOrder.forEach(fieldKey => {
            if (unsortedJson[fieldKey] != null) {
              sortedList.push(unsortedJson[fieldKey])
            }
          })
          sorteDisplayColumn = JSON.parse(JSON.stringify(sortedList))

          if (lastCol != null) {
            sorteDisplayColumn.push(lastCol)
          }
          this.customEntity.columns = sorteDisplayColumn
      }
    },
    view(id) {
      this.showView = true
      this.resourceId = id
    },

    async refreshData() {
      this.fetchList()
      this.getUserInfo()
    },

    displayPermissions(permissions) {
      return this.$lodash.groupBy(permissions, 'name')
    },

    create() {
      this.resourceId = null
      this.showForm = true
    },

    edit(id) {
      if (this.$can('Update', this.permissionName) === false) return
      this.resourceId = id
      this.showForm = true
    },

    remove({ uuid_key, version }) {
      if (this.$can('Delete', this.permissionName) === false) return
      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.deleteResource(uuid_key, version)
          this.removeCustomEntity(uuid_key)
        }
      })
    },

    onCloseForm() {
      this.resourceId = null
      this.showForm = false
      this.showHistory = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/custom-entity-data.scss';
</style>
