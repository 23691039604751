<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center"
      style="height: calc(100vh - 165px)"
    >
      <b-spinner class="align-middle" />
    </div>
    <custom-entity-table
      v-else
      :default-search-field="defaultSearchField"
      :custom-service="service"
      :custom-entity="customEntity"
    />
  </div>
</template>

<script>
import { customEntityRowsService } from '@/services/customEntityData'
import { columnsWithFavorites, formatColumnsForTable } from '@/helpers/customEntity'
import customEntityService from '@/services/customEntity'
import RootEvents from '@/constants/rootEvents'
import CustomEntityTable from './Table.vue'

export default {
  components: {
    CustomEntityTable,
  },
  data() {
    return {
      loading: true,
      service: null,
      defaultSearchField: null,
      customEntity: null,
    }
  },
  watch: {
    '$route.params': {
      handler(value) {
        if (value.key) {
          this.getInitialData(value.key)
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getInitialData(key) {
      this.loading = true
      this.service = customEntityRowsService(key)

      const { response } = await this.$async(customEntityService.getOne(key))
      this.customEntity = response.data

      this.$root.$emit(RootEvents.SET_PAGE_TITLE, this.customEntity.label)
      this.$root.$emit(RootEvents.UPDATE_BREADCRUMB_ITEM, {
        value: {
          text: this.customEntity.label,
          active: true,
        },
      })

      this.customEntity.columns = columnsWithFavorites(this.customEntity, formatColumnsForTable(this.customEntity.columns).value)

      // Set default search field on dropdown
      const [defaultSearchField] = this.customEntity.columns.filter(item => item.searchable)
      this.defaultSearchField = defaultSearchField ? defaultSearchField.key : null

      this.loading = false
    },
  },
}
</script>
